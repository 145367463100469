import React, { createElement, useCallback, useMemo, useRef, useState } from "react";
import {
  DefaultEwgefh020MarktwertProps,
  PlasmicEwgefh020Marktwert,
  PlasmicEwgefh020Marktwert__OverridesType
} from "./plasmic/imbas_marktwert_seiten/PlasmicEwgefh020Marktwert";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { EWGParameters, withCurrentEWGParameters } from "../withCurrentEWGParameters";
import { FormWrapperProps } from "./custom/FormWrapper";
import { flow } from "lodash";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import withInitialResourceLoad from "../store/withInitialResourceLoad";
import { withAutosaveProvider } from "../store/AutosaveProvider";
import { DataStoreName, useDataStore, withDataStoreProvider } from "../store/DataStoreProvider";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import {
  getMarktwertCalc,
  getMarktwertInput,
  getMarktwertMod,
  MarktwertInput,
  saveMarktwertInput,
  saveMarktwertInputDraft
} from "../api/endpoints/marktwert";
import { isErrorResponse, processErrorResponse } from "../api/apiClient";
import { downloadBlob, getFileNameFromContentDisposition } from "../utils";
import { getReportBlob } from "../api/endpoints/report";
import { useTranslation } from "react-i18next";
import { BoxPlotChartData } from "./custom/BoxPlotChart";
import { Country, Language, useRegionContext } from "../context/RegionContext";
import ComparableTypeProvider from "../context/ComparablesContext";
import { useModalContext } from "../context/ModalContext";
import { useIdTokenClaims } from "../auth/authUtils";
import { useDebugContext } from "../context/DebugContext";

// TODO: get this data from API
export const boxplotMockData: BoxPlotChartData = {
  labels: [
    "Altbau",
    "Neubau",
  ],
  datasets: [{
    label: "Angebote",
    backgroundColor: "#FF0000",
    data: [
      {
        min: 548438,
        q1: 745875,
        median: 877500,
        q3: 1044225,
        max: 1294313,
      },
      {
        min: 866250,
        q1: 976500,
        median: 1050000,
        q3: 1113000,
        max: 1207500,
      }
    ],
  }, {
    label: "Transaktionen",
    backgroundColor: "#99D1FF",
    data: [
      {
        min: 405900,
        q1: 605160,
        median: 738000,
        q3: 863460,
        max: 1051650,
      },
      {
        min: 685800,
        q1: 883920,
        median: 1016000,
        q3: 1219200,
        max: 1524000,
      }
    ],
  }],
};

type ApiErrors = FormWrapperProps<MarktwertInput>["apiErrors"];

export interface Ewgefh020MarktwertProps extends DefaultEwgefh020MarktwertProps, EWGParameters {
}

const downloadShortReport = (objectId: string,
  sprache: Language,
  isDebug: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  return getReportBlob(objectId, sprache, true, isDebug).then(({ data, headers }) => {
    const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
    downloadBlob(data, fileName, setIsLoading);
  });
};


function Ewgefh020Marktwert_(
  { objectId, ...props }: Ewgefh020MarktwertProps,
  ref: HTMLElementRefOf<"div">
) {
  const { language, country } = useRegionContext();
  const { showComparablesModal, setShowComparablesModal } = useModalContext();
  const { t } = useTranslation();
  const { dataStore: { marktwertInput, marktwertMod } } = useDataStore();
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState<ApiErrors>();
  const { isDebugMode, setIsDebugMode } = useDebugContext();
  const submitActionRef = useRef<"navigate" | "downloadReport" | null>(null);
  const { i18n } = useTranslation();
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(Boolean(false));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(Boolean(false));
  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo || [];
  const remmsModuleActive = allowedModules.some(
    ({ ModulName, Land }) => ModulName === "REMMS" && Country[Land] === country
  );

  const onSubmit = useCallback(async (data: MarktwertInput) => {
    try {
      if (submitActionRef.current === "navigate") {
        setIsSubmitting(true);
        await saveMarktwertInput(objectId, language, data);
        navigate(ApplicationRoutes.getPath("ewg030Bericht", { objectId }))
      } else if (submitActionRef.current === "downloadReport") {
        setIsLoadingReport(true);
        await saveMarktwertInput(objectId, language, data);
        await downloadShortReport(objectId, language, isDebugMode, setIsLoadingReport);
      }
    } catch (err) {
      if (isErrorResponse(err)) {
        setApiErrors(processErrorResponse(err.response.data, i18n.language));
      }
    } finally {
      setIsSubmitting(false);
      setIsLoadingReport(false);
      submitActionRef.current = null;
      setShowComparablesModal(false);
      setIsDebugMode(false);
    }
  }, [navigate, objectId, isDebugMode]);

  const specificComponentAdjustments: PlasmicEwgefh020Marktwert__OverridesType = {

    renovationsbedarfInput1: { isDisabled: marktwertMod?.SperrenRenovationsbedarfAnteilFeld },
    renovationsbedarfInput2: { isDisabled: marktwertMod?.SperrenRenovationsbedarfAnteilFeld },
    renovationsbedarfInput3: { isDisabled: marktwertMod?.SperrenRenovationsbedarfAnteilFeld },
    erstellungskostenInput: { isDisabled: marktwertMod?.SperrenErstellungskostenFeld },
    landwertInput: { isDisabled: marktwertMod?.SperrenLAND },
    submitButton: {
      isLoading: isSubmitting,
      isDisabled: isSubmitting,
      onClick: () => submitActionRef.current = "navigate"
    },
    submitAndDownloadButton: {
      isLoading: isLoadingReport,
      isDisabled: isLoadingReport,
      onClick: () => submitActionRef.current = "downloadReport"
    },
    flaechenpreiseBoxPlot: {
      title: t("box_plot.title_1", { defaultValue: "Verteilung der Preise in CHF/m²" })!,
      data: boxplotMockData,
    },
    stueckpreiseBoxplot: {
      title: t("box_plot.title_2", { defaultValue: "Verteilung der Stückpreise in CHF" })!,
      data: boxplotMockData,
    },
    volsia03: t("{{value, number}}", { value: String(marktwertMod?.VOLSIA03) }),
    hinweisGebaudevolumen: {
      render: (props, Component) => (
        marktwertMod?.HinweisGebäudevolumen &&
        <Component {...props} />
      )
    },
  };

  return (
    <AutoSavingFormWrapper
      className=""
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      defaultValues={marktwertInput}
    >
      <ComparableTypeProvider>
        <PlasmicEwgefh020Marktwert
          root={{ ref }}
          {...specificComponentAdjustments}
          {...props}
          showComparablesModal={showComparablesModal}
          processCardSachwert={{ isHidden: country === Country.DE }}
          processCardSachwertImmowertv={{ isHidden: country !== Country.DE }}
          processCardErtragswert={{ isHidden: country === Country.DE }}
          processCardErtragswertImmowertv={{ isHidden: country !== Country.DE  }}
          processCardPreisentwicklung={{ isHidden: country === Country.LU }}
          processCardRemms={{ isHidden: !remmsModuleActive }}
        />
      </ComparableTypeProvider>
    </AutoSavingFormWrapper>
  );
}

const Ewgefh020Marktwert = React.forwardRef(Ewgefh020Marktwert_);
export default withCurrentEWGParameters((props) => {
  const { objectId } = props;
  const { language } = useRegionContext();

  const composedComponent = useMemo(() => {
    return flow([
      withInitialResourceLoad([
        { apiCall: () => getMarktwertInput(objectId), dataStoreName: DataStoreName.marktwertInput },
        { apiCall: () => getMarktwertMod(objectId, language), dataStoreName: DataStoreName.marktwertMod },
        { apiCall: () => getMarktwertCalc(objectId, language), dataStoreName: DataStoreName.marktwertCalc }
      ]),
      withAutosaveProvider({
        apiCall: (data) => saveMarktwertInputDraft(objectId, language, data),
        autosaveDataStore: DataStoreName.marktwertCalc,
        userInputDataStore: DataStoreName.marktwertInput,
      }),
      withDataStoreProvider({}),
    ])(Ewgefh020Marktwert)
  }, [objectId, language]);

  return createElement(composedComponent, props);
});
