import * as React from "react";
import { useContext } from "react";
import { DefaultNavButtonProps, PlasmicNavButton } from "./plasmic/imbas_23_fpre/PlasmicNavButton";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ScrollNavContext } from "./ScrollNav";
import { useProcessCardsCtx } from "../context/ProcessCardsContext";

export interface NavButtonProps extends DefaultNavButtonProps {
  onClick?: () => void;
}

function NavButton_(
 { scrollTo, ...props }: NavButtonProps, 
  ref: HTMLElementRefOf<"div">) 
{
  const { activeButton, setActiveButton } = useContext(ScrollNavContext);
  const { processCardContainer, dispatch } = useProcessCardsCtx();

  return (
    <PlasmicNavButton
      root={{ ref }}
      {...props}
      {...(scrollTo && {
        onClick: () => {
          dispatch({
            type: "toggle_card",
            payload: { id: scrollTo ?? "", isClosed: false }
          })
          setActiveButton(scrollTo ?? "")

        },
        isActive: activeButton === scrollTo,
      })}
    />
  );
}

const NavButton = React.forwardRef(NavButton_);
export default NavButton;
